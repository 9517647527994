/* *,*::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: calibri;
} */

/* body {
  display: flex;
  justify-content: center;
} */

swiper-container {
  width: 100%;
  height: 100%;
  
}

.slider-main-container {
  position: relative;
  padding-inline: 10%;
}

swiper-slide {
 width: 100%;
 height: 100%;
 display: flex;
 justify-content: center;
}

swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav-btn {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 20;
  user-select: none;
  -webkit-user-select: none;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  padding: 5px;
  border-radius: 50px;
  transition: background 0.5s ease;
}

.nav-btn:hover {
  background-color: #572682;
}

.nav-btn.custom-prev-button {
  top: 50%;
  left: 2em;
}

.nav-btn.custom-next-button {
  right: 2em;
  top: 50%;
}

.main-grid-item-icon-left{
  padding-inline-end: 2px;
}
.main-grid-item-icon-right{
  padding-inline-start: 2px;
}