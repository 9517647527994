.tab-style {
  text-transform: "capitalize";
  font-weight: 600;
  border-style: "solid";
  border-width: 1;

  border-top-left-radius: 15;
  border-top-right-radius: 15;
}

.learn-how-banner{
  background-size: cover !important;
}

@media only screen and (max-width: 536px) {
  .learn-how-banner{
    background-size: contain !important;
  }
}