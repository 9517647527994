.home-page-container{
    background-color: #f0f0f0;
    padding-bottom: 70px;
   
}
.top-section-container{
    background-color: #fff;
}
.last-container-home{
   padding-bottom: 30px;
}

.home-banner-img{
    object-fit: contain;
    width: 100%;
}