@import url("/src/style/Pages/Auth/Auth.css");
@import url("/src/style/Pages/Home/Home.css");
@import url("/src/style/Pages/MySuite/MySuite.css");
@import url("/src/style/Pages/Auth/SignUp.css");
/* @import url("/src/style/Components/YoutubeEmbed/YouTubeEmbed.css"); */

/* Organism */
@import url("/src/style/Components/Organism/Footer.css");
@import url("/src/style/Swiper/swiper.css");

table th {
    color: #fff !important;
    font-family: Source sans pro !important ;
    font-weight: 600 !important;
}
table td p {
    font-family: Source sans pro !important ;
}

input ::placeholder {
    font-family: Source sans pro !important ; 
}



