.footer-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  /* display: flex;
  justify-content: center; */
}
.footer-ul li a {
  font-size: 1.1rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 35px;
  text-decoration: none;
  
}

.footer-ul li a:hover{
  font-size: 1.1rem;
  font-weight: 500;
  color: rgb(118, 186, 0);
  line-height: 35px;
  text-decoration: none;
  
}
.footer-logo{
  width: 60%;
  height: auto;
  object-fit: contain;
}

@media only screen and (max-width: 899px) {
.footer-ul{
  text-align: center;
}
.footer-logo{
  width: 20%;
  object-fit: contain;
}

}

@media only screen and (max-width: 599px) {
  .footer-logo{
    width: 30%;
    object-fit: contain;
  }
  
}