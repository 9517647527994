.sign-in-container{
    justify-content: center;
    align-items: center;
    height: 100%;
}
.auth-right-container{
    display: flex;
    align-items: center;
}

.forgot-pw-txt{
    cursor: pointer;
    color: aqua;
}

.auth-header{
    color:#572682;
    font-weight: 500 !important;
}


